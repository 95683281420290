import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text';
import { Title } from '@keytrade/components-title';
import { Paragraph as SubTitle } from '@keytrade/components-paragraph';

import TabPagesBlock, {
  TabPagesBlockType,
} from '@/components/blocks/TabPagesBlock';
import { mediaQuery } from '@/utils/helpers';
import Page, { PageType } from '@/components/Page';
import RichText from '@/components/Richtext';
import BlockWrapper from '@/components/BlockWrapper';
import Container from '@/components/Container';
import { LinkStyle } from '@/components/Link';
import ExternalLink from '@/components/ExternalLink';
import useTranslations from '@/hooks/useTranslations';

type InfoPageType = {
  title: string;
  subtitle: string;
  tabPagesBlock: TabPagesBlockType;
  content: RenderRichTextData<ContentfulRichTextGatsbyReference>;
};

type InfoPageData = {
  pageType: InfoPageType;
  slug: string;
};

type Props = PageType & {
  data: {
    contentfulPage: InfoPageData;
  };
};

const TabsBlockWrapper = styled(BlockWrapper)`
  position: relative;
  top: -14rem;
  margin-bottom: -14rem;
  ${mediaQuery.md('top: -21rem; margin-bottom: -21rem;')}
`;

type HeaderBlockWrapperProps = {
  hasTabs: boolean;
};

const HeaderBlockWrapper = styled(BlockWrapper)<HeaderBlockWrapperProps>`
  ${({ hasTabs }) =>
    hasTabs &&
    `padding-bottom:9rem;${mediaQuery.md('padding-bottom:11.2rem;')}`}
`;

const COOKIE_POLICY_PAGE_SLUGS = [
  'use-cookies',
  'gebruik-cookies',
  'usage-cookies',
];

const InfoPageTemplate: React.FC<Props> = (props) => {
  const { t } = useTranslations();

  const infoPage: InfoPageType = props.data.contentfulPage.pageType;
  const pageSlug = props.data.contentfulPage.slug;
  const { title, subtitle, tabPagesBlock, content } = infoPage;
  const hasTabs = tabPagesBlock !== null;

  const isCookiePolicyPage = COOKIE_POLICY_PAGE_SLUGS.includes(pageSlug);

  if (isCookiePolicyPage && typeof window !== 'undefined') {
    setTimeout(() => {
      if (window['OneTrust']) {
        window['OneTrust'].changeLanguage();
      }
    }, 50);
  }

  return (
    <Page {...props}>
      <HeaderBlockWrapper
        color='Grey Background'
        hasTabs={hasTabs}
        padding={!hasTabs && 'small'}
        margin={'0'}
      >
        <Container narrow>
          <Title size='xxxl' level='h1' margin='11.4rem 0 16px'>
            {title}
          </Title>
          {subtitle && (
            <SubTitle color='GreyBlue' weight='bold'>
              {subtitle}
            </SubTitle>
          )}
        </Container>
      </HeaderBlockWrapper>
      {tabPagesBlock && (
        <TabsBlockWrapper color='Transparent' padding='bottom'>
          <TabPagesBlock
            {...tabPagesBlock}
            backgroundColor={'TransparentDark'}
            narrow={true}
          />
        </TabsBlockWrapper>
      )}
      {content && (
        <BlockWrapper color='White' padding='small'>
          <Container narrow>
            <RichText data={content} locale={props.pageContext.locale} />
          </Container>
        </BlockWrapper>
      )}
      {isCookiePolicyPage && (
        <BlockWrapper color='White' padding='small'>
          <Container narrow>
            <div id='ot-sdk-cookie-policy'></div>
            <SubTitle color='GreyBlue'>
              <ExternalLink
                locale={props.pageContext.locale}
                linkStyle={LinkStyle.INLINE}
                url='#cookie-preferences'
              >
                <b>{t('your_cookies_preferences')}</b>
              </ExternalLink>
            </SubTitle>
          </Container>
        </BlockWrapper>
      )}
    </Page>
  );
};

export default InfoPageTemplate;

export const infoPageQuery = graphql`
  query InfoPageQuery($contentfulId: String!, $locale: String) {
    contentfulPage(
      contentful_id: { eq: $contentfulId }
      node_locale: { eq: $locale }
    ) {
      slug
      node_locale
      seoMetadata {
        ...SeoMetaDataFragment
      }
      headerBackground
      pageType {
        ... on ContentfulInfoPageType {
          content {
            ...RichTextFragment
          }
          title
          subtitle
          tabPagesBlock {
            ...TabPagesBlockFragment
          }
        }
      }
    }
    contentfulNavigation(node_locale: { eq: $locale }) {
      ...NavigationFragment
    }
    contentfulLoginDialog(node_locale: { eq: $locale }) {
      ...LoginFragment
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      ...FooterFragment
    }
    allLanguages: allContentfulPage(
      filter: { contentful_id: { eq: $contentfulId } }
    ) {
      nodes {
        node_locale
        ...PagePathFragment
      }
    }
  }
`;
