import React from 'react';
import { graphql } from 'gatsby';

import SEOMeta, { SEOMetaType } from '@/components/seo/SEOMeta';
import { generateAlternates } from '@/utils/SlugUtils';
import DefaultLayout from '@/components/DefaultLayout';
import Footer, { FooterData } from '@/components/Footer';
import { LanguageSlug } from '@/components/navigation/LanguageSwitch';
import LanguageRedirect from '@/components/LanguageRedirect';
import ChatBot from '@/components/chatbot/Chatbot';
import Main from '@/components/shared/Main';
import Navigation, {
  LoginData,
  NavigationData,
} from '@/components/navigation/Navigation';

export type PageInfoType = {
  contentful_id: string;
  slug?: string;
  __typename: string;
  node_locale: string;
  parentPage?: PageInfoType;
  redirect?: PageType;
};
export type PageTypeInfoType = PageInfoType & {
  pageType: {
    __typename: string;
  };
};

export type DefaultPaths = {
  blogPath?: string;
  supportPath?: string;
};

export type Path = {
  lang: string;
  url: string;
};

export type PageData = {
  seoMetadata?: SEOMetaType;
  pageType?: any;
  node_locale: string;
  parentPage?: PageInfoType;
  headerBackground?: string;
  updated?: boolean;
};

export type PageType = {
  data: {
    contentfulPage: PageData;
    allLanguages: {
      nodes: Array<LanguageSlug>;
    };
    contentfulNavigation: NavigationData;
    contentfulLoginDialog: LoginData;
    contentfulFooter: FooterData;
  };
  path: string;
  location: URL;
};

type Props = PageType;

const Page: React.FC<Props> = (props) => {
  const ContentNavigation = props.data.contentfulNavigation;
  const ContentLogin = props.data.contentfulLoginDialog;
  const data: PageData = props.data.contentfulPage;
  const path: Path = { lang: data.node_locale, url: props.path };
  const alternates: Array<Path> = generateAlternates(
    props.data.allLanguages.nodes,
  );

  const backgroundColor: string = props.data.contentfulPage.headerBackground;

  return (
    <DefaultLayout>
      <SEOMeta
        path={path}
        alternates={alternates}
        seoMetadata={data.seoMetadata || ({} as SEOMetaType)}
      />
      <LanguageRedirect
        languageSlugs={props.data.allLanguages.nodes}
        location={props.location}
      />
      <Navigation
        {...ContentNavigation}
        {...ContentLogin}
        languageSlugs={props.data.allLanguages.nodes}
        pageColor={backgroundColor}
        locale={data.node_locale}
        location={props.location}
      />
      <Main>{props.children}</Main>
      <Footer {...props.data.contentfulFooter} />
      <ChatBot locale={data?.node_locale} />
    </DefaultLayout>
  );
};
export default Page;
export const pagePathFragmentQuery = graphql`
  fragment PagePathFragment on ContentfulPage {
    contentful_id
    slug
    parentPage {
      ... on ContentfulPage {
        contentful_id
        slug
        parentPage {
          ... on ContentfulPage {
            contentful_id
            slug
            parentPage {
              ... on ContentfulPage {
                contentful_id
                slug
                parentPage {
                  ... on ContentfulPage {
                    contentful_id
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const pagePathAndTypeFragmentQuery = graphql`
  fragment PageRichTextFragment on ContentfulPage {
    contentful_id
    node_locale
    ...PagePathFragment
    pageType {
      __typename
    }
  }
`;
